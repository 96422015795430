new WOW({ offset: 70 }).init();

function loadSliders() {

	$('.center').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 3,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});

	$('.photo-gallery__images').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 4,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {

	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		window.scrollBy(0, 1);
	});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();
	});

	$(".toplinks__dropdown-link").each(function (index, value) {
		$(this).attr("data-wow-delay", index / 10 + "s");
		$(this).attr("data-wow-offset", 0);
		$(this).addClass("wow fadeIn");
	});

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$('.testimonials__link').sameHeight();
		$(".clients__carousel .carousel-inner").css("height", "auto");
		$(".clients__carousel .carousel-inner").sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

	$('.clients__carousel').on('slide.bs.carousel', function (ev) {
		var dir = ev.direction == 'right' ? 'prev' : 'next';
		$('.clients__carousel').not('.sliding').addClass('sliding').carousel(dir);
	});
	$('.clients__carousel').on('slid.bs.carousel', function (ev) {
		$('.clients__carousel').removeClass('sliding');
	});

});

